import { Play } from "../components/Icons";
// import React, { useEffect } from "react";
import { osName, CustomView } from "react-device-detect";

export default function Install() {
  // let comp;

  // useEffect(() => {
  //   const UA = navigator.userAgent;

  //   const IOS = UA.match(/iPhone|iPad|iPod/);
  //   const ANDROID = UA.match(/Android/);

  //   const PLATFORM = IOS ? "ios" : ANDROID ? "android" : "unknown";

  //   const standalone = window.matchMedia("(display-mode: standalone)").matches;

  //   if (standalone || (IOS && !UA.match(/Safari/))) {
  //     let promoandroid = document.getElementsByClassName(
  //       "pb-3.5 pt-3.5 px-2 py-1 -ml-32 bg-blue-600 text-gray-100 rounded-sm hover:bg-red-400 hover:text-white transform hover:scale-105 transition-all duration-300 visible md:invisible"
  //     );
  //     promoandroid[0].style.display = "none";

  //     //fixed ios
  //   } else if ((!ANDROID && standalone) || (IOS && UA.match(/Safari/))) {
  //     let promoandroid = document.getElementsByClassName(
  //       "pb-3.5 pt-3.5 px-2 py-1 -ml-32 bg-blue-600 text-gray-100 rounded-sm hover:bg-red-400 hover:text-white transform hover:scale-105 transition-all duration-300 visible md:invisible"
  //     );
  //     promoandroid[0].style.display = "none";
  //     //fixed ios
  //   } else if (ANDROID || (!standalone && !IOS && !UA.match(/Safari/))) {
  //     let promowindows = document.getElementsByClassName(
  //       "pb-3.5 pt-3.5 px-2 py-1 -ml-32 bg-blue-600 text-gray-100 rounded-sm hover:bg-red-400 hover:text-white transform hover:scale-105 transition-all duration-300 visible md:invisible"
  //     );
  //     promowindows[0].style.display = "none";
  //   } else {
  //   }
  // }, []);

  // if (!window.matchMedia("(display-mode: standalone)").matches) {
  //   comp = (
  //     <button
  //       className="pb-3.5 pt-3.5 px-2 py-1 -ml-32 bg-blue-600 text-gray-100 rounded-sm hover:bg-red-400
  //           hover:text-white transform hover:scale-105 transition-all duration-300 visible md:invisible"
  //       onClick={() =>
  //         window.open(
  //           "https://play.google.com/store/apps/details?id=in.seelay.archeus"
  //         )
  //       }
  //     >
  //       <span className="flex">
  //         <Play /> &nbsp;Install App{" "}
  //       </span>
  //     </button>
  //   );
  // }

  return (
    <CustomView
      condition={
        osName === "Android" &&
        !window.matchMedia("(display-mode: standalone)").matches
      }
    >
      <button
        className="pb-3.5 pt-3.5 px-2 py-1 bg-blue-600 text-gray-100 rounded-sm hover:bg-red-400
            hover:text-white transform hover:scale-105 transition-all duration-300 "
        onClick={() => window.open("https://app.archeus.seelay.in")}
      >
        <span className="flex">
          <Play /> &nbsp;Install App{" "}
        </span>
      </button>
    </CustomView>
  );
}
